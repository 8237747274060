<template>
  <!-- Placeholder is being replaced by Drupal. -->
  <a v-if="isIsland" href="$USER_URL$" class="meta-nav-link">
    <SpriteSymbol name="lock" />
    <span>$USER_NAME$</span>
  </a>

  <!-- The dropdown is visible if a user is logged in or if there are other accounts to log in. -->
  <Dropdown
    v-else-if="drupalUser.isLoggedIn"
    dropdown-class="right-0 w-300"
    class="relative"
    :transition-name="isMobileMenu ? 'user-meta' : undefined"
    :always-render="true"
    :disable-click-away="isMobileMenu"
  >
    <template #toggle="{ onClick }">
      <button
        class="meta-nav-link"
        :class="
          isPharmacy
            ? '!text-berry hover:!text-berry-light'
            : '!text-orange hover:!text-orange-light'
        "
        @click="onClick"
      >
        <SpriteSymbol :name="loggedInIcon" class="mr-5 is-text-sized" />

        <!-- If logged in show the other accounts. -->
        <span v-if="drupalUser.readableName">{{
          drupalUser.readableName
        }}</span>

        <!-- Show login text. -->
        <span v-else>{{ $texts('login', 'Login') }}</span>
      </button>
    </template>

    <template #default="{ close, visible }">
      <Teleport to="body">
        <div
          v-if="visible && mobileMenuOpen"
          class="fixed top-0 left-0 w-full h-full z-[45] lg:hidden"
          @click.capture.prevent="close"
        />
      </Teleport>
      <div v-if="drupalUser.isLoggedIn" class="relative z-10">
        <button
          class="p-20 flex items-center font-semibold border-b border-b-gray-300 w-full text-md mb-10 lg:hidden"
          :class="
            isPharmacy
              ? '!text-berry hover:!text-berry-light'
              : '!text-orange hover:!text-orange-light'
          "
          @click="close"
        >
          <SpriteSymbol :name="loggedInIcon" class="mr-5 is-text-sized" />

          <span>{{ drupalUser.readableName }}</span>
        </button>
        <div
          v-if="drupalUser.isMasquerading"
          class="border-b border-gray-300 bg-red"
        >
          <button
            class="text-sm block px-15 py-10 w-full text-white font-medium"
            @click="unmasquerade"
          >
            <span>Nicht mehr als anderer Nutzer ausgeben</span>
          </button>
        </div>
        <DrupalLink
          v-for="link in profileLinks"
          :key="link.id"
          v-bind="link.linkProps"
          class="meta-nav-link block px-15 py-10 w-full"
          @click="close"
        >
          <span>{{ link.label }}</span>
        </DrupalLink>
      </div>
      <div class="border-t border-gray-300">
        <button
          class="meta-nav-link block px-15 py-10 w-full"
          @click.prevent="() => logoutRedirect('/')"
        >
          <span>{{ $texts('logout', 'Logout') }}</span>
        </button>
      </div>
      <ul class="border-t border-gray-300">
        <li v-for="action in changeAccountActions" class="relative group">
          <button
            class="meta-nav-link block px-15 py-10 w-full min-w-0 pr-30"
            @click.prevent="() => logoutRedirect(action.destination)"
          >
            <SpriteSymbol
              :name="action.icon"
              class="mr-5 w-15 h-15 shrink-0"
              :class="action.iconColor"
            />
            <span
              class="overflow-ellipsis block whitespace-nowrap overflow-hidden"
              >{{ action.label }}</span
            >
          </button>
          <button
            class="absolute top-1/2 -translate-y-1/2 right-0 z-10 h-full items-center px-10 bg-white hidden group-hover:flex"
            @click="removeUserFromHistory(action.id)"
          >
            <SpriteSymbol name="close" class="w-15 h-15" />
          </button>
        </li>
      </ul>
      <div class="border-t border-gray-300">
        <button
          class="meta-nav-link block px-15 py-10 w-full"
          @click.prevent="() => logoutRedirect('/user/login')"
        >
          {{ $texts('loginWithAnotherAccount', 'Mit anderem Konto anmelden') }}
        </button>
      </div>
    </template>
  </Dropdown>

  <nuxt-link v-else :to="{ name: 'login' }" class="meta-nav-link">
    <SpriteSymbol name="lock" />
    <span>{{ $texts('login', 'Anmelden') }}</span>
  </nuxt-link>
</template>

<script lang="ts" setup>
import { useDrupalUser } from '~/stores/drupalUser'

defineProps<{
  isIsland?: boolean
}>()

const language = useCurrentLanguage()

async function logoutRedirect(destination: string) {
  let dest = `/${language.value}`
  if (destination !== '/') {
    dest += destination
  }
  await drupalUser.logout(dest)
}

const drupalUser = useDrupalUser()
const { isMobileMenu } = useViewport()

const changeAccountActions = computed(() => {
  return drupalUser.userNameHistory
    .filter((v) => v.id !== drupalUser.id)
    .map((v) => {
      return {
        id: v.id,
        label: v.readableName || v.name,
        destination: `/user/login?user=${v.name}`,
        icon: v.isPharmacy ? 'pharmasuisse' : 'individual_member',
        iconColor: v.isPharmacy ? 'text-berry' : 'text-orange',
      }
    })
})

async function unmasquerade() {
  await useGraphqlMutation('masqueradeSwitchBack')
  window.location.href = `/${language.value}/user`
}

const profileLinks = drupalUser.profileLinks()

const isPharmacy = computed(() => {
  return drupalUser.isAffiliatedPharmacy || drupalUser.isNonAffiliatedPharmacy
})

const loggedInIcon = computed(() => {
  return isPharmacy.value ? 'pharmasuisse' : 'individual_member'
})

function removeUserFromHistory(id: string) {
  drupalUser.removeUserFromHistory(id)
}

const mobileMenuOpen = inject<Ref<boolean> | null>('mobileMenuOpen', null)
</script>

<style lang="postcss">
.user-meta-enter-active,
.user-meta-leave-active {
  @apply transition origin-top duration-300;
}
.user-meta-enter-from,
.user-meta-leave-to {
  @apply until-lg:translate-x-full;
}
</style>
