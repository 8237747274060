<template>
  <ul class="meta-nav">
    <li>
      <nuxt-link :to="{ name: 'search' }" class="meta-nav-link">
        <SpriteSymbol name="search" class="lg:!m-0" />
        <span class="lg:hidden">{{ $texts('search', 'Suchen') }}</span>
      </nuxt-link>
    </li>

    <li>
      <MetaNavCart :language="language" />
    </li>

    <li>
      <a :href="ihreApothekeLink.href" target="_blank" class="meta-nav-link">
        <span>{{ ihreApothekeLink.text }}</span>
      </a>
    </li>

    <li>
      <a :href="fphchLink.href" target="_blank" class="meta-nav-link">
        <span>{{ fphchLink.text }}</span>
      </a>
    </li>

    <li>
      <MetaNavLanguageSwitcher :is-island="isIsland" />
    </li>

    <li>
      <MetaNavUser :is-island="isIsland" />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type { PageLanguage } from '#language-negotiation/language'

const props = defineProps<{
  language: PageLanguage
  isIsland?: boolean
}>()

// External link to Ihre Apotheke.
const ihreApothekeLink = computed(() => {
  switch (props.language?.toLowerCase()) {
    case 'fr':
      return {
        href: 'https://ihre-apotheke.ch/fr',
        text: 'votre-pharmacie.ch',
      }
    case 'it':
      return {
        href: 'https://ihre-apotheke.ch/it',
        text: 'vostra-farmacia.ch ',
      }
    default:
      return {
        href: 'https://www.ihre-apotheke.ch',
        text: 'ihre-apotheke.ch',
      }
  }
})

// External link to fphch.
const fphchLink = computed(() => {
  switch (props.language?.toLowerCase()) {
    case 'fr':
      return {
        href: 'https://fphch.org/fr',
        text: 'fphch.org',
      }
    case 'it':
      return {
        href: 'https://fphch.org/de',
        text: 'fphch.org',
      }
    default:
      return {
        href: 'https://fphch.org/de',
        text: 'fphch.org',
      }
  }
})
</script>

<style lang="postcss"></style>
